<template lang="pug">
  v-dialog(
    v-model="open"
    width="400"
    eager
  )
    v-card
      v-container
        v-row
          v-col(cols="12")
            v-combobox(
              :label="`${value.id} Units`"
              v-model="unit"
              :items="unitList"
              multiple
              chips
              hide-details
              @change="unitChange"
              item-value="id"
              item-text="id"
              :error-messages="stockCategoryUnitStorePostErrors.units"
            )
              template(v-slot:selection="{ item, index, selected }")
                v-chip(
                  color="blue-grey darken-3"
                  dark
                  :input-value="selected"
                  label
                  small
                )
                  span.mr-2 {{ item.id || item }}
                  v-icon(small @click="deleteItem(index)") $delete
              template(v-slot:item="{ item }")
                span.mr-2.primary--text {{ item.id }}
                small.blue-grey--text {{ item.description || 'N/A' }}
    base-snackbar(
      :errors.sync="stockCategoryUnitStorePostErrors"
    )
</template>
<style lang="sass" scoped>
  .style >>> .v-input__slot::before 
    border-style: none !important
</style>
<script>
import stockCategoryRepository from '@/repositories/stock-category.repository'
import unitRepository from '@/repositories/unit.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [unitIndexVars, unitIndexVarNames] = requestVars({ identifier: 'unit' })
const [stockCategoryUnitStoreVars, stockCategoryUnitStoreVarNames] = requestVars({ identifier: 'stock-category-unit-store', hasData: false, request: 'post' })

const unitIndexHandler = new VueRequestHandler(null, unitIndexVarNames)
const stockCategoryUnitStoreHandler = new VueRequestHandler(null, stockCategoryUnitStoreVarNames)

const inputVars = () => ({
  unit: [],
})

export default {
  name: 'CreateStockCategoryUnit',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ...unitIndexVars,
      ...stockCategoryUnitStoreVars,
      ...inputVars(),
    }
  },
  created () {
    this.getUnits()
  },
  computed: {
    open: {
      get () {
        return !this.$objectEmpty(this.value)
      },
      set (val) {
        if (val) return
        this.$emit('input', { })
      },
    },
    units () {
      if (!this.value.units) return
      return this.value.units
    },
  },
  watch: {
    units () {
      this.unit = this.units
    },
    open (val) {
      if (!val) return
      this.getUnits()
    },
  },
  methods: {
    getUnits () {
      const handler = unitIndexHandler
      const repository = unitRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    unitChange () {
      const handler = stockCategoryUnitStoreHandler
      const repository = stockCategoryRepository.attachUnits
      const units = this.unit.map(item => item.id || item)
      handler.setVue(this)
      handler.execute(repository, [this.value.id, { units }])
    },
    deleteItem (index) {
      this.unit.splice(index, 1)
      this.unitChange()
    },
  },
}
</script>